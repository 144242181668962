<script>
// @ is an alias to /src

import { defineComponent, onMounted, inject } from "vue";
export default defineComponent({
  name: "HomeD",

  setup() {
    const router = inject("router");

    onMounted(() => {
      router.push({ name: "Home" });
    });
  },
});
</script>
