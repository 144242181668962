<template>
  <div class="row">
    <div class="col-12">
      <div class="card concard card-shadow">
        <div class="card-body mt-2 white" style="text-align: center; padding: 50px">
          <div style="font-size: 30px; font-weight: bold; color: green">
            ยินดีต้อนรับสู่
          </div>
          <div class="mb-3" style="font-size: 15px; font-weight: bold; color: green">
            การอบรมเชิงปฎิบัติการระบบศูนย์ดำรงธรรมออนไลน์และการเพิ่มประสิทธิภาพการแก้ไขปัญหาข้อร้องเรียนร้องทุกข์
            จังหวัดนครศรีธรรมราช
          </div>

         
        </div>
        <div class="d-flex justify-center align-center mt-3 mb-3">
            <img src="@/assets/a.jpg" style="width: 100%" alt="..." />
          </div>
          <div class="d-flex justify-center align-center mb-47">
            <img src="@/assets/b.jpg" style="width: 100%" alt="..." />
          </div>

        <div class="card-footer white">
          <div class="d-flex justify-center align-center">
            <button @click="close" class="button">ปิดหน้านี้</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, inject, ref } from "vue";
import liff from "@line/liff";
export default defineComponent({
  name: "Profile",
  components: {},
  setup() {
    const store = inject("store");
    const router = inject("router");
    const active = ref(0);

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const close = () => {
      liff.ready.then(() => {
        liff.closeWindow();
      });
    };

    return {
      close,
      active,
    };
  },
});
</script>

<style scoped>
.mn-profile {
  height: 50px;
  min-height: 50px;
  border-bottom: 2px solid var(--color);
  padding: 10px;
  background-color: white;
}
.mini-h {
  font-size: 20px;
  margin-left: 5px;
}
</style>
