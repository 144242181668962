import ApiService from "@/service/ApiService";

export default function useLogin() {
  const CheckUser = async (id) => {
    ApiService.setHeader();
    return await ApiService.get("checkregister_smart_city_officer_dmt/" + id);
  };

  const CheckStatus = async () => {
    return await ApiService.get("check_status_checkin_dmt");
  };

  const CheckIn = async () => {
    return await ApiService.post("checkin_smart_city_officer_dmt");
  };



  


  

  return {
    CheckUser,
    CheckStatus,
    CheckIn
  };
}
