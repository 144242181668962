export const SET_REGISTER = "setRegister";

export default {
  state: {
    register: {
      'province' :'นครศรีธรรมราช',
      'fn_status' : false,
      'job_status' : false
    },
  },
  getters: {
    getRegister(state) {
      return state.register;
    },
  },
  actions: {
    [SET_REGISTER](state, payload) {
      state.commit(SET_REGISTER, payload);
    },
  },
  mutations: {
    [SET_REGISTER](state, payload) {
      state.register = {
        ...state.register,
        ...payload,
      };
    },
  },
};
