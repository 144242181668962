import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Done from "@/views/Done.vue";
import Profile from "@/views/profile/Profile.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/done",
    name: "Done",
    component: Done,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
